<template>
  <div class="terms">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <div class="switch-cover">
      <div class="container">
        <div class="row">

          <div class="col-md-5 " >
            <div class="p-5 bg-white sign-up-card" >

              <form method="post" action="#" v-on:submit.prevent="validateSignUp">

                <div >
                  <div>
                    <h3>Register your account</h3>
                  </div>

                  <div class="mb-3">
                    <h5>Sign Up</h5>
                  </div>

                  <div class="form-group mb-1">
                    <label :class="{ 'text-danger': $v.user.name.$error }">First Name </label> <br/>
                    <input v-model.trim="$v.user.name.$model" class="form-control" type="text" placeholder="Your first name" />
                    <div class="text-danger" v-if="!$v.user.name.required && $v.user.name.$dirty">First name is required</div>
                  </div>

                  <div class="form-group mb-1">
                    <label >Middle Name </label> <br/>
                    <input v-model="user.middle_name" class="form-control" type="text" placeholder="Your last name" />
                  </div>

                  <div class="form-group mb-1">
                    <label :class="{ 'text-danger': $v.user.surname.$error }">Last Name </label> <br/>
                    <input v-model.trim="$v.user.surname.$model" class="form-control" type="text" placeholder="Your last name" />
                    <div class="text-danger" v-if="!$v.user.surname.required && $v.user.surname.$dirty">Last name is required</div>
                  </div>

                  <div class="form-group mb-1">
                    <label :class="{ 'text-danger': $v.user.email.$error }">Email </label> <br/>
                    <input v-model.trim="$v.user.email.$model" class="form-control" type="text" placeholder="Your email" />
                    <div class="text-danger" v-if="!$v.user.email.required && $v.user.email.$dirty">Email address is required</div>
                    <div class="text-danger" v-if="!$v.user.email.email && $v.user.email.$dirty">Email invalid</div>
                  </div>

                  <div class="form-group mb-1">
                    <label :class="{ 'text-danger': $v.user.phone.$error }">Phone Number </label> <br/>
                    <input v-model.trim="$v.user.phone.$model" class="form-control" type="text" placeholder="(+254)712345678" />
                    <div class="text-danger" v-if="!$v.user.phone.required && $v.user.phone.$dirty">Phone number is required</div>
                  </div>

                  <div class="form-group mb-1">
                    <label :class="{ 'text-danger': $v.user.password.$error }">Password </label> <br/>
                    <input v-model.trim="$v.user.password.$model"  class="form-control" type="password" placeholder="Your password"  />
                    <div class="text-danger" v-if="!$v.user.password.required && $v.user.password.$dirty" >Password is required</div>
                  </div>

                  <div class="form-group mb-2">
                    <label :class="{ 'text-danger': $v.user.confirmPassword.$error }">Confirm Password </label> <br/>
                    <input v-model.trim="$v.user.confirmPassword.$model"  class="form-control" type="password" placeholder="Confirm password"  />
                    <div class="text-danger" v-if="!$v.user.confirmPassword.required && $v.user.confirmPassword.$dirty" >Password is required</div>
                  </div>


                  <div class="row mb-2">
                    <div class="col-md-12" style="font-size: 12px;">
                      By signing in, you agree to our <a style="color:#0077BE;" href="#">Terms & Condition</a> and <a style="color:#0077BE;"   href="#">Privacy Policy</a>.*
                    </div>

                  </div>

                  <div class="mb-2 ">
                    <button type="submit"  style="background-color: #E42B1A !important;color:#fff;" class=" btn-secondary btn  btn-lg  quote-btn btn-block">  <strong>Sign up </strong>  </button>
                  </div>

                  <div class="row mb-5">

                    <div class="col-md-12 text-center" style="font-size: 12px;" >
                      Already have account? <RouterLink style="color: #E42B1A;" class=" text-bold" to="/login">Login</RouterLink>
                    </div>

                  </div>
                </div>

              </form>


              <!--<div class="divider mt-5 mb-5 text-center">
                <span>or continue with</span>
              </div>

              <div class="mb-5 text-center sso-sign-up">
                <a href="#"><img src="@/client/assets/images/facebook.png"></a>
                <a href="#"><img src="@/client/assets/images/google.png"></a>
                <a href="#"><img src="@/client/assets/images/apple.png"></a>
              </div>-->




            </div>


          </div>
          <div class="col-md-7">

          </div>
        </div>
      </div>
    </div>

    <modal name="success-checkout" height="300">
      <div class="p-5 text-center success-checkout">
        <img src="@/client/assets/images/success_check.png">


        <h4 class="text-success">Payment successful</h4>

        <div>
          <button type="button" class="btn  btn-secondary btn-block quote-btn" > Proceed To dashboard </button>
        </div>

      </div>

    </modal>

    <modal name="stk-push-wait" height="300">
      <div class="p-5 text-center ">
        <p>
          Kindly check your phone for an STK push <br/>
          to <strong>enter your Mpesa pin</strong> to complete <br/>
          the payment.
        </p>

        <div class="mt-3">
          <img src="@/client/assets/images/progress.png">
        </div>

        <div class="mt-3 mb-3">
          <a href="/personal-details" class="btn  btn-secondary btn-block quote-btn" > OK </a>
        </div>
      </div>

    </modal>

  </div>
</template>

<script>

  import { required,email} from 'vuelidate/lib/validators'
  import { mapActions } from "vuex";
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import firebase from 'firebase/app'
  import 'firebase/auth'
  import  'firebase/database';

  export default {
    name: 'home',
    data () {
      return {
        isLoading: false,
        fullPage: true,
        user:{
          name: "",
          surname: "",
          gender: "",
          date_of_birth: "",
          national_id: "",
          email: "",
          phone: "",
          emergency_contacts: "",
          emergency_phones: "",
          country: "Kenya",
          city: "Nairobi",
          number_plates: [],
          privileges: {
            level: 5
          },
          packages: {},
          date_onboarded: "",
          date_onboarded_unix: 1669371758.412,
          role: "GUEST",
          middle_name: "",
          aicare_client_company: "62b115210f8cbb6064f4b251",
          status: "ACTIVE",
          vehicles: [],
          password: "",
          confirmPassword:""
        }
      };
    },
    mounted(){
      this.quotation = JSON.parse(localStorage.getItem("quote"));
    },

    components: { Loading },

    validations: {
      user:{
        email:{required, email},
        password:{required},
        name:{required},
        surname:{required},
        phone:{required},
        confirmPassword:{required},
      },
    },

    methods:{
      ...mapActions(["makePayment","createUser"]),

      addUsertoRTStore(){

        let self = this;

        this.isLoading = true;

        this.createUser(this.user).then((response) => {
          self.isLoading = false;

          self.$toast.open({
            message: "Success!",
            type: 'success',
            duration:5000,
            position:"top-right"
          });

          //self.$router.push("/login");


        }).catch(error => {
          self.isLoading = false;
          console.log(error);
        });

      },
      signUpUser(){

        this.isLoading = true;
        let self = this;
        firebase.auth().createUserWithEmailAndPassword(this.user.email, this.user.password)
                .then((userCredential) => {

                  self.isLoading = false;

                  self.addUsertoRTStore();

                  self.$toast.open({
                    message: "You have been registered successfully",
                    type: 'success',
                    duration:5000,
                    position:"top-right"
                  });

                  self.$router.push("/login");

                })
                .catch((error) => {
                  self.isLoading = false;

                  self.$toast.open({
                    message: "An error occurred. Please try again",
                    type: 'error',
                    duration:5000,
                    position:"top-right"
                  });

                });
      },

      validateSignUp(){
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.signUpUser()
        }
      },
      goBack(){
        this.$router.push('/quote');
      },

    }
  }
</script>

<style>

  table td{
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .personal-info{
    min-height: 80vh;
    background-color: #F2F2F2;
  }


  .sso-sign-up a{
    border:1px solid #BDBDBD;
    padding: 15px;
    margin: 5px;
    border-radius: 4px;
  }
  .sso-sign-up img{
      height: 30px;
      width: 30px;
  }
   .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    color: #666666;
    font-size: 16px;
    font-weight: 600;
  }
  .nav-link:hover {
    border: none;
  }

  label{
    color:#000;
  }
  .personal-info button{
    width: 100%;
  }


</style>
